import { ActionTree } from 'vuex';
import { FileInfoState } from './types';
import { RootState } from '../../types';
import FileInfo from '@/store/model/file-info';
import Config from '@/store/config';

const actions: ActionTree<FileInfoState, RootState> = {
  async getFilesInformationRequest({ commit }): Promise<void> {
    try {
      const response = await fetch(`${Config.PROTOCOL}://${Config.HOST}/files`);
      const data = await response.json();
      commit('setFileInformation', data as FileInfo[]);
      commit('setConnectionStatus', 'Successed');
      localStorage.setItem('filesInfo', JSON.stringify(data));
    } catch (err) {
      console.log(err);
      commit('setConnectionStatus', 'Error');
    }
  },
  async deleteFileRequest({ commit }, fileUid): Promise<void> {
    try {
      await fetch(`${Config.PROTOCOL}://${Config.HOST}/files/${fileUid}`, {
        method: 'DELETE',
      });
      commit('setConnectionStatus', 'Successed');
    } catch {
      commit('setConnectionStatus', 'Error');
    }
  },
  async getFilesMetricsRequest({ commit }, fileUid): Promise<void> {
    try {
      const response = await fetch(`${Config.PROTOCOL}://${Config.HOST}/report/${fileUid}`);
      const body = await response.json();
      commit('setMetrics', body as Record<string, Record<string, Array<any>>>);
      commit('setFields', Object.keys(body));
      commit('setConnectionStatus', 'Successed');
      const storageData = localStorage.getItem('metrics');
      if (storageData != null) {
        const metrics = JSON.parse(storageData);
        metrics[`${fileUid}`] = body;
        localStorage.setItem('metrics', JSON.stringify(metrics));
      } else {
        localStorage.setItem('metrics', JSON.stringify({ [fileUid]: body }));
      }
    } catch {
      commit('setConnectionStatus', 'Error');
    }
  },
  async uploadFileOnServer({ commit }, data: FormData): Promise<void> {
    try {
      await fetch(`${Config.PROTOCOL}://${Config.HOST}/files`, {
        method: 'POST',
        body: data,
        headers: {},
      });
      commit('setConnectionStatus', 'Successed');
    } catch {
      commit('setConnectionStatus', 'Error');
    }
  },
};

export default actions;
