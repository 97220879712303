
import moment from 'moment';
import { Vue } from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';

const namespace = 'sidebar';

export default class UploaderMoadal extends Vue {
  months = moment.months();

  declare $refs: any;

  @Getter('uploadFile', { namespace: 'filesInfo' }) uploadFile?: File;

  @Action('getFilesInformationRequest', { namespace: 'filesInfo' }) getFilesInformationRequest!: CallableFunction;

  @Mutation('setUploadFile', { namespace: 'filesInfo' }) setUploadFile!: CallableFunction;

  @Getter('isShowUploaderModal', { namespace }) isShowUploaderModal?: boolean;

  @Action('closeUploaderModal', { namespace }) closeUploaderModal!: CallableFunction;

  @Action('uploadFileOnServer', { namespace: 'filesInfo' }) uploadFileOnServer!: CallableFunction;

  async upload(event: Event) {
    event.preventDefault();
    const formData = new FormData(this.$refs.uploadForm);
    await this.uploadFileOnServer(formData);
    await this.getFilesInformationRequest();
  }

  dragEnter(event: Event) {
    event.preventDefault();
    this.$refs.zone.classList.add('gray');
  }

  dragOut(event: Event) {
    event.preventDefault();
    this.$refs.zone.classList.remove('gray');
  }

  updateText(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    const files: FileList = target.files as FileList;
    this.setUploadFile(files.item(0));
  }

  drop(event: any) {
    event.preventDefault();
    const files: FileList = event.dataTransfer.files as FileList;
    if (files != null) {
      this.$refs.myFile.files = files;
      this.setUploadFile(this.$refs.myFile.files.item(0));
    }
    this.$refs.zone.classList.remove('gray');
  }
}
