<template>
    <div class="files">
      <h3 class="title">Результаты метеоданных</h3>
      <div class="files__table-container">
        <table class="files__table">
          <thead class="files__header">
            <tr class="files__header-container">
              <th width="210" class="left" >uid</th>
              <th class="left">Наименование</th>
              <th width="120">Месяц</th>
              <th width="90">Год</th>
            </tr>
          </thead>
          <tbody class="files__body">
            <tr v-for="fileInfo in filesInfo" @click="goToFile(fileInfo.uid)"
              :key="fileInfo.uid">
              <td width="210" class="left">{{fileInfo.uid}}</td>
              <td class="left">{{fileInfo.name}}</td>
              <td width="120">{{fileInfo.month}}</td>
              <td width="90">{{fileInfo.year}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';
import FileInfo from '@/store/model/file-info';

const namespace = 'filesInfo';

export default class ResultTable extends Vue {
  @Getter('fields', { namespace }) fields!: Array<string>;

  @Getter('filesIformation', { namespace }) filesInfo!: Array<FileInfo>;

  @Action('getFilesMetricsRequest', { namespace }) getFileMetricsRequest: any;

  @Getter('connectionStatus', { namespace }) connectionsStatus: any;

  @Mutation('setMetrics', { namespace }) setMetrics!: CallableFunction;

  @Mutation('setFields', { namespace }) setFields!: CallableFunction;

  async goToFile(uid: string) {
    const metricsData: any = localStorage.getItem('metrics');
    let fileData;

    if (metricsData !== undefined && metricsData !== null) {
      fileData = JSON.parse(metricsData)[`${uid}`];
    }

    if (fileData !== undefined) {
      const parsedData = fileData as Record<string, Record<string, Array<any>>>;
      this.setFields(Object.keys(parsedData));
      this.setMetrics(parsedData);
      this.$router.push({ path: `/inspect/${uid}/${this.fields[0]}` });
    } else {
      await this.getFileMetricsRequest(uid);
      if (this.connectionsStatus === 'Successed') {
        this.$router.push({ path: `/inspect/${uid}/${this.fields[0]}` });
      }
    }
  }
}
</script>

<style lang="less" scoped>

  .reload
  {
    color: #858585;
    margin-left: 10px;
    border:1px solid #67B22C;
    background-color: #67B22C;
    padding: 5px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    * {
      cursor: pointer;
    }
  }

  .sup
  {
    font-weight: 500;
    height: 50px;
    text-align: center;
    border: 0px;
    color:white;
    padding: 0px 20px;
  }

  .page__header
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
  }

  .files
  {
    font-family: "Ubuntu";
    width: 100%;
    height: calc(100vh - 40px);

    &__table-container
    {
      height: calc(100% - 80px);
      margin-right: 20px;
      overflow-y:scroll;
      padding-right: 20px;
    }

    &__table-container::-webkit-scrollbar {
      width: 3px;
      border-radius: 5px;
    }

    &__table-container::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #dadada;
    }

    &__table-container::-webkit-scrollbar-thumb {
      background-color: #67B22C;
      outline: 1px solid #F0F0F0;
      border-radius: 5px;
    }

    &__table
    {
      th:first-child{
        border-radius:3px 0 0 0px;
      }

      th:last-child{
        border-radius:0 3px 0px 0;
      }
      border-spacing:0px;
      width: 100%;
    }

    &__header-container
    {
      background-color: #5EA329;
      th
      {
       .sup
      }
    }

    &__body
    {
      tr
      {
        background-color: #fff;
        &:hover
        {
          cursor: pointer;
          background-color: #9aca75;
          td
          {
             color:white;
          }
        }
        &:nth-child(odd)
        {
          &:hover
          {
            cursor: pointer;
            background-color: #9aca75;
            color:white;
          }
          background-color: #f5f5f5;
        }
        td
        {
          .sup;
          font-weight: 400;
          color: #525252;
        }
      }
    }
  }

  .left
  {
    text-align: left !important;
  }

</style>
