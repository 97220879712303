<template>
    <div class="sidebar__profile">
        <div class="profile__container">
            <transition name="fade">
                <div class="connection fail" v-if="(connectionStatus == 'Error')">
                    <object fill="#fff" class="close_svg"
                        type="image/svg+xml"
                        :data="require('@/components/sidebar/assets/close.svg')"
                        alt="">
                    </object>
                    <p v-if="isShow">Ошибка подключения к серверу !</p>
                </div>
            </transition>
            <transition name="fade">
                <div class="connection successed" v-if="(connectionStatus == 'Successed')">
                    <object fill="#fff" class="close_svg"
                        type="image/svg+xml"
                        :data="require('@/components/sidebar/assets/success.svg')"
                        alt="">
                    </object>
                    <p v-if="isShow">Данные успешно обновлены !</p>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Getter } from 'vuex-class';

const namespace = 'filesInfo';

export default class SidebarProfile extends Vue {
    @Getter('connectionStatus', { namespace }) connectionStatus!: string;

    @Getter('isShow', { namespace: 'sidebar' }) isShow?: boolean;
}
</script>

<style lang="less" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .connection
    {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .successed {
            color: #858585
        }

        .fail {
            color:rgb(172, 54, 54);
        }
        p
        {
            line-height: 21px;
            font-size: 14px;
            margin-left: 20px;
        }
    }

    .profile__container
    {
        width: 100%;
        padding: 0px 20px;
        .close_svg
        {
            width: 40px;
            height: 40px;
        }
    }
    .sidebar__profile
    {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 80px;
        background-color: #EFEFEF;
    }

</style>
