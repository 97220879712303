<template>
    <div class="sidebar-filter">
      <UploaderModal></UploaderModal>
      <h3 class="title">Фильтр</h3>
      <div class="filter">
        <label for="">Месяц</label>
        <select class="filter">
          <option value="all">все</option>
          <option v-for="month in this.months" :key="month" :value="month">
            {{month}}
          </option>
        </select>
        <label for="">Год</label>
        <input value="2021" type="number" min="1990" max="2100">
      </div>
      <div class="actions">
        <button @click="update()" class="uploader__modal-call">
            <object fill="#fff" class="drag_zone-image"
              type="image/svg+xml"
              :data="require('./assets/reload.svg')"
              alt="">
            </object>
        </button>
        <button @click="openUploaderModal()" class="uploader__modal-call">
           <object fill="#fff" class="drag_zone-image"
              type="image/svg+xml"
              :data="require('@/components/uploader/assets/upload.svg')"
              alt="">
            </object>
        </button>
        <button @click="clearStorage()" class="uploader__modal-call">
            <object fill="#fff" class="drag_zone-image"
              type="image/svg+xml"
              :data="require('./assets/filter.svg')"
              alt="">
            </object>
        </button>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action } from 'vuex-class';
import moment from 'moment';
import UploaderModal from '@/components/uploader/UploaderModal.vue';

const namespace = 'sidebar';

@Options({
  components: {
    UploaderModal,
  },
})
export default class SidebarFilter extends Vue {
  months = moment.months();

  @Action('openUploaderModal', { namespace }) openUploaderModal: any;

  @Action('getFilesInformationRequest', { namespace: 'filesInfo' }) getFilesInformationRequest: any;

  async clearStorage() {
    localStorage.clear();
    await this.getFilesInformationRequest();
  }

  async update() {
    await this.getFilesInformationRequest();
  }
}
</script>

<style lang="less" scoped>
.actions
{
  display: flex;
}

.filter
{
  padding: 20px;
  width: 320px;
  background-color: white;
  border:1px solid #EFEFEF;
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu";
  font-weight: 300;
  color: #525252;

  label
  {
    margin-bottom: 15px;
  }

  select, input
  {
    font-family: "Ubuntu";
    margin-bottom: 20px;
    padding: 8px;
    border: 1px solid #e7e7e7;
  }
}

.uploader__modal-call
{
  font-size: 16px;
  margin-right: 10px;
  margin-top: 20px;
  font-family: "Ubuntu";
  background-color: #fff;
  border: 1px solid #EFEFEF;
  padding: 10px;
  border-radius: 3px;
  color: white;
  cursor: pointer;

  object
  {
    width: 25px;
    height: 25px;
    pointer-events: none;
  }

  &:hover
  {
    background-color: #e7e7e7;
  }
}
</style>
