import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Result from '@/views/Result.vue';
import FileInspection from '@/views/FileInspection.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/result',
    name: 'results',
    component: Result,
  },
  {
    path: '/inspect/:uid/:type',
    name: 'inspect',
    component: FileInspection,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
