import { ActionTree } from 'vuex';
import { SidebarState } from './types';
import { RootState } from '../../types';

const actions: ActionTree<SidebarState, RootState> = {
  switchView({ commit }): void {
    commit('showChange');
  },

  openUploaderModal({ commit }): void {
    commit('setOpenUploaderModal');
  },

  closeUploaderModal({ commit }): void {
    commit('setCloseUploaderModal');
  },
};

export default actions;
