<template>
    <div class="sidebar__menu">
        <nav class="sidebar__nav">
            <router-link class="sidebar__nav-item" to="/result">
                <object fill="#fff" class="sidbar__svg"
                type="image/svg+xml"
                :data="require('@/components/sidebar/assets/result.svg')"
                alt="">
                </object>
                <p>Результаты</p>
            </router-link>
        </nav>
    </div>
</template>

<style lang="less" scoped>

    .sidebar__menu
    {
        margin-top: 150px;
        height: 100%;
    }

    .sidebar__nav
    {
        padding: 0px 20px 0px 20px;
        object
        {
            pointer-events: none;
        }
        .router-link-active
        {
            background-color: #67B22C !important;
            color:white !important;
            .sidbar__svg
            {
                filter: brightness(0) invert(1);
            }
        }

        &-item
        {
            height: 50px;
            display: flex;
            padding: 0px 13px;
            align-items: center;
            text-decoration: none;
            font-family: 'Ubuntu';
            color: #757575;
            font-size: 16px;
            border-radius: 3px;
            overflow: hidden;
            margin-bottom: 10px;
            transition: all 0.3s;
            p
            {
                font-weight: 500;
                margin: 0px 0px 0px 20px;
            }
            .sidbar__svg
            {
                height: 25px;
                width: 25px;
            }

            &:hover
            {
                background-color: #F6F6F6;
            }
        }

    }

</style>
