
import { Vue } from 'vue-class-component';
import { Getter } from 'vuex-class';

const namespace = 'filesInfo';

export default class SidebarProfile extends Vue {
    @Getter('connectionStatus', { namespace }) connectionStatus!: string;

    @Getter('isShow', { namespace: 'sidebar' }) isShow?: boolean;
}
