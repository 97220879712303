<template>
    <div id="not-found">
        <object fill="#fff" class="svg"
            type="image/svg+xml"
            :data="require('@/assets/not-found.svg')"
            alt="">
        </object>
        <p>Страница не найдена</p>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class FileInspectation extends Vue {
}
</script>

<style lang="less">

    #not-found
    {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: calc(100vh - 40px);
        .svg
        {
            width: 40%;
            height: 40%;
        }

        p
        {
            color: #616161;
            font-size: 28px;
            font-weight: 500;
        }
    }
</style>
