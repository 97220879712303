 <template>
    <div id="result-page">
      <div class="page__container">
        <ResultTable></ResultTable>
        <SidebarFilter></SidebarFilter>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action, Mutation } from 'vuex-class';
import ResultTable from '@/components/result-table/ResultTable.vue';
import SidebarFilter from '@/components/sidebar-filter/SidebarFIlter.vue';
import FileInfo from '@/store/model/file-info';

const namespace = 'filesInfo';

@Options({
  components: {
    ResultTable,
    SidebarFilter,
  },
})
export default class ResultView extends Vue {
  @Action('getFilesInformationRequest', { namespace }) getFilesInformationRequest!: CallableFunction;

  @Mutation('setFileInformation', { namespace }) setFilesInfo!: CallableFunction;

  async beforeMount() {
    const data = localStorage.getItem(namespace);
    if (data == null) {
      await this.getFilesInformationRequest();
    } else {
      this.setFilesInfo(JSON.parse(data) as FileInfo[]);
    }
  }
}
</script>

<style lang="less" scoped>
  #result-page
  {
    .page__container
    {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
</style>
