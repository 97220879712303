
import { Options, Vue } from 'vue-class-component';
import ApexCharts from 'apexcharts';
import { Action, Getter } from 'vuex-class';
import Config from '@/store/config';
import FileInfo from '@/store/model/file-info';

@Options({
  components: {
    ApexCharts,
  },
})
export default class Inspectation extends Vue {
  @Getter('filesIformation', { namespace: 'filesInfo' }) filesInfo!: Array<FileInfo>;

  @Getter('metrics', { namespace: 'filesInfo' }) metrics!: Record<string, Record<string, Array<any>>>;

  @Action('deleteFileRequest', { namespace: 'filesInfo' }) deleteFileRequest!: Promise<CallableFunction>;

  getLinkExcel() {
    return `${Config.PROTOCOL}://${Config.HOST}/excel/${this.$route.params.uid}`;
  }

  optionsData = {
    colors: ['#67B22C'],
    chart: {
      type: 'line',
    },
    stroke: {
      curve: 'smooth',
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: () => {
        const chartType = this.$route.params.type;
        if (chartType) {
          const metricsData = this.metrics[`${chartType}`];
          if (metricsData) {
            return Object.keys(metricsData).map((date) => date.split('.')[0]);
          }
        }
        return undefined;
      },
    },
  }

  getMonth() {
    const fileInfoData = localStorage.getItem('filesInfo');
    if (fileInfoData !== null) {
      const fileInfo = JSON.parse(fileInfoData) as FileInfo[];
      let el: any;
      fileInfo.forEach((element) => {
        console.log(element);
        if (element.uid === this.$route.params.uid) {
          el = element;
        }
      });
      if (el !== undefined) {
        return `${el.month} ${el.year}`;
      }
    }
    return '...';
  }

  copyToClipboard(textToCopy: string): any {
    console.log(this);
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    }
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      const command = document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }

  seriesData() {
    const chartType = this.$route.params.type;
    if (chartType) {
      const metricsData = this.metrics[`${chartType}`];
      const metricsDates = Object.keys(metricsData);
      const chartData: any[] = [];
      if (metricsData) {
        for (let i = 0; i < metricsDates.length; i += 1) {
          const value: any = this.metrics[`${chartType}`][metricsDates[i]];
          if (Number(value) === value && value % 1 !== 0) {
            chartData.push(value.toFixed(2));
          } else {
            chartData.push(value);
          }
        }
        const series = [{
          name: chartType,
          data: chartData,
        }];
        return series;
      }
    }
    return undefined;
  }
}
