
import { Options, Vue } from 'vue-class-component';
import { Getter } from 'vuex-class';
import Sidebar from '@/components/sidebar/Sidebar.vue';

@Options({
  components: {
    Sidebar,
  },
})
export default class App extends Vue {
    @Getter('isShow', { namespace: 'sidebar' }) isShow?: boolean;
}
