
import { Options, Vue } from 'vue-class-component';
import { Getter } from 'vuex-class';
import SidebarMenu from '@/components/sidebar/SidebarMenu.vue';
import SidebarProfile from '@/components/sidebar/SidebarProfile.vue';
import SidebarFooter from '@/components/sidebar/SidebarFooter.vue';

const namespace = 'sidebar';

@Options({
  components: {
    SidebarMenu,
    SidebarProfile,
    SidebarFooter,
  },
})
export default class Sidebar extends Vue {
  @Getter('isShow', { namespace }) isShow?: boolean;
}
