// profile/mutations.ts
import { MutationTree } from 'vuex';
import { FileInfoState } from './types';

const mutations: MutationTree<FileInfoState> = {
  setFileInformation(state, payload) {
    state.files = payload;
  },
  setConnectionStatus(state, payload) {
    state.connectionStatus = payload;
    setTimeout(() => {
      state.connectionStatus = '';
    }, 5000);
  },
  setMetrics(state, payload) {
    state.metrics = payload;
  },
  setFields(state, payload) {
    state.fields = payload;
  },
  setUploadFile(state, payload) {
    state.uploadFile = payload;
  },
};

export default mutations;
