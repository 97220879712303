<template>
    <div class="sidebar-inspect">
      <h3 class="title">Значения</h3>
      <ul class="inspect__fields">
          <li v-for="field in fields" :key="field">
              <router-link :to="`${field.replaceAll('/', '%2f')}`">
                {{field}}
              </router-link>
          </li>
      </ul>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';

@Options({
  props: {
    fields: Array as PropType<string[]>,
  },
})
export default class SidebarInspect extends Vue {
    fields!: Array<string>
}
</script>

<style lang="less" scoped>
.sidebar-inspect
{
  .inspect__fields
  {
    height: calc(100vh - 150px);
    width: 420px;
    background-color: white;
    border:1px solid #EFEFEF;
    padding:10px 0px;
    margin: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #dadada;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #67B22C;
      outline: 1px solid #F0F0F0;
      border-radius: 5px;
    }

    li
    {
      font-family: "ubuntu";
      list-style-type: none;
      a
      {
        padding: 0px 25px;
        min-height: 40px;
        line-height: 40px;
        display: block;
        text-decoration: none;
        color:#525252;
      }

      .router-link-active
      {
        color:white;
        background-color:#67B22C;
      }

      &:hover
      {
        background-color: #E3E3E3;
        cursor: pointer;
      }
    }
  }
}
</style>
