
import { Options, Vue } from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';
import Inspectation from '@/components/inspectation/Inspectation.vue';
import SidebarInspect from '@/components/sidebar-inspect/SidebarInspect.vue';
import NotFound from '@/components/NotFound.vue';

const namespace = 'filesInfo';

@Options({
  components: {
    NotFound,
    Inspectation,
    SidebarInspect,
  },
})
export default class FileInspectation extends Vue {
  @Getter('fields', { namespace }) fields!: Array<string>;

  @Action('getFilesMetricsRequest', { namespace }) getFilesMetricsRequest: any;

  @Mutation('setMetrics', { namespace }) setMetrics!: CallableFunction;

  @Mutation('setFields', { namespace }) setFields!: CallableFunction;

  async beforeMount() {
    const chartType = this.$route.params.type;
    const uid = this.$route.params.uid as string;
    const metricsData: any = localStorage.getItem('metrics');
    let fileData;
    if (metricsData !== undefined && metricsData !== null && uid !== null) {
      fileData = JSON.parse(metricsData)[`${uid}`];
    }

    if (fileData !== undefined && fileData !== null && chartType !== undefined) {
      this.setMetrics(fileData);
      this.setFields(Object.keys(fileData));
      this.$router.push({ path: `/inspect/${uid}/${this.fields[0]}` });
    } else {
      await this.getFilesMetricsRequest(uid);
      this.$router.push({ path: `/inspect/${uid}/${this.fields[0]}` });
    }
  }
}
