
import { Vue } from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';
import FileInfo from '@/store/model/file-info';

const namespace = 'filesInfo';

export default class ResultTable extends Vue {
  @Getter('fields', { namespace }) fields!: Array<string>;

  @Getter('filesIformation', { namespace }) filesInfo!: Array<FileInfo>;

  @Action('getFilesMetricsRequest', { namespace }) getFileMetricsRequest: any;

  @Getter('connectionStatus', { namespace }) connectionsStatus: any;

  @Mutation('setMetrics', { namespace }) setMetrics!: CallableFunction;

  @Mutation('setFields', { namespace }) setFields!: CallableFunction;

  async goToFile(uid: string) {
    const metricsData: any = localStorage.getItem('metrics');
    let fileData;

    if (metricsData !== undefined && metricsData !== null) {
      fileData = JSON.parse(metricsData)[`${uid}`];
    }

    if (fileData !== undefined) {
      const parsedData = fileData as Record<string, Record<string, Array<any>>>;
      this.setFields(Object.keys(parsedData));
      this.setMetrics(parsedData);
      this.$router.push({ path: `/inspect/${uid}/${this.fields[0]}` });
    } else {
      await this.getFileMetricsRequest(uid);
      if (this.connectionsStatus === 'Successed') {
        this.$router.push({ path: `/inspect/${uid}/${this.fields[0]}` });
      }
    }
  }
}
