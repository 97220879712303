<template>
  <transition name="fade">
    <div id="uploader" class="modal" v-show="isShowUploaderModal">
        <div @click="closeUploaderModal" class="modal-outside"></div>
        <form ref="uploadForm" class="modal-container" id="upload-form" name="upload-form">
          <div class="modal-header">
            <button @click="closeUploaderModal" class="close-btn">x</button>
          </div>
          <div ref="zone" class="drag_zone"
            @drop="drop"
            @dragover="dragEnter"
            @dragleave="dragOut"
            >
            <object fill="#fff" class="drag_zone-image"
              type="image/svg+xml"
              :data="require('@/components/uploader/assets/upload.svg')"
              alt="">
            </object>
            <label for="file" class="form__btn">
              <p v-if="this.uploadFile !== undefined" >Выбран файл {{this.uploadFile.name}}</p>
              <p v-else>Перетащите или <i class="unique">выберите файл</i></p>
            </label>
            <input
            @change="updateText"
            ref="myFile"
            id="file"
            type="file"
            name="file"
            accept=".txt"/>
          </div>
          <div class="filter">
            <label for="">Месяц</label>
            <input name="month" value="1" type="number" min="1" max="12">
            <label for="">Год</label>
            <input name="year" value="2021" type="number" min="1990" max="2100">
          </div>
          <p class="modal-description">
            Для выгрузки небходимо вставить файл исключтельно .txt формата.
          </p>
          <button class="send" @click="upload">Отправить</button>
        </form>
    </div>
  </transition>
</template>
<script lang="ts">
import moment from 'moment';
import { Vue } from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';

const namespace = 'sidebar';

export default class UploaderMoadal extends Vue {
  months = moment.months();

  declare $refs: any;

  @Getter('uploadFile', { namespace: 'filesInfo' }) uploadFile?: File;

  @Action('getFilesInformationRequest', { namespace: 'filesInfo' }) getFilesInformationRequest!: CallableFunction;

  @Mutation('setUploadFile', { namespace: 'filesInfo' }) setUploadFile!: CallableFunction;

  @Getter('isShowUploaderModal', { namespace }) isShowUploaderModal?: boolean;

  @Action('closeUploaderModal', { namespace }) closeUploaderModal!: CallableFunction;

  @Action('uploadFileOnServer', { namespace: 'filesInfo' }) uploadFileOnServer!: CallableFunction;

  async upload(event: Event) {
    event.preventDefault();
    const formData = new FormData(this.$refs.uploadForm);
    await this.uploadFileOnServer(formData);
    await this.getFilesInformationRequest();
  }

  dragEnter(event: Event) {
    event.preventDefault();
    this.$refs.zone.classList.add('gray');
  }

  dragOut(event: Event) {
    event.preventDefault();
    this.$refs.zone.classList.remove('gray');
  }

  updateText(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    const files: FileList = target.files as FileList;
    this.setUploadFile(files.item(0));
  }

  drop(event: any) {
    event.preventDefault();
    const files: FileList = event.dataTransfer.files as FileList;
    if (files != null) {
      this.$refs.myFile.files = files;
      this.setUploadFile(this.$refs.myFile.files.item(0));
    }
    this.$refs.zone.classList.remove('gray');
  }
}
</script>

<style lang="less" scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.send
{
  width: 100%;
  border: 1px solid #EFEFEF;
  background-color: #fff;
  padding: 15px;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
}

.filter
{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  color: #525252;

  label
  {
    margin-bottom: 15px;
  }

  select, input
  {
    font-family: "Ubuntu";
    margin-bottom: 20px;
    padding: 8px;
    border: 1px solid #e7e7e7;
  }
}

.gray {
  background-color: #ececec !important;
}

.modal {
  font-family: 'Ubuntu';
  position: fixed; /* Stay in place */
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */

  &-description {
    width: 100%;
    text-align: center;
  }
  &-outside
  {
    z-index: 2;
    position: fixed; /* Stay in place */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  .close-btn
  {
    float: right;
    border:none;
    background-color: #fff;
    font-size: 21px;
    cursor: pointer;
  }

  &-header
  {
    height: 30px;
    margin-bottom: 10px;
  }

  &-container
  {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    max-width: 444px;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
  }
}

.form__btn
{
    box-sizing:border-box;
    text-align:center;
    font-family:'Ubuntu';
    display:block;
    color:#575757;
    font-weight: 400;
    text-decoration: none;
    padding: .8em 1em calc(.8em + 3px);
    border-radius: 3px;
    i {
      font-weight: 500;
      cursor: pointer;
      text-decoration-line: underline;
    }
}

#file
{
    display: none;
}

.drag_zone
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 400px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    z-index: 2;

    &-image
    {
      pointer-events: none;
      width: 100px;
      height: 100px;
    }

    label, p
    {
      pointer-events: none;
    }

    .unique
    {
      pointer-events:all;
    }
}
</style>
