import { Module } from 'vuex';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { FileInfoState } from './types';
import { RootState } from '../../types';

export const state: FileInfoState = {
  files: [],
  connectionStatus: '',
  fields: [],
  uploadFile: undefined,
  metrics: {},
};

const namespaced = true;

export const filesInfo: Module<FileInfoState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
