
import { Options, Vue } from 'vue-class-component';
import { Action } from 'vuex-class';
import moment from 'moment';
import UploaderModal from '@/components/uploader/UploaderModal.vue';

const namespace = 'sidebar';

@Options({
  components: {
    UploaderModal,
  },
})
export default class SidebarFilter extends Vue {
  months = moment.months();

  @Action('openUploaderModal', { namespace }) openUploaderModal: any;

  @Action('getFilesInformationRequest', { namespace: 'filesInfo' }) getFilesInformationRequest: any;

  async clearStorage() {
    localStorage.clear();
    await this.getFilesInformationRequest();
  }

  async update() {
    await this.getFilesInformationRequest();
  }
}
