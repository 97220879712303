// index.ts
import { StoreOptions, createStore } from 'vuex';
import { RootState } from './types';
import { sidebar } from './modules/sidebar';
import { filesInfo } from '@/store/modules/files-info';

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    sidebar,
    filesInfo,
  },
};

export default createStore(storeOptions);
