
import { Getter, Action } from 'vuex-class';
import { Vue } from 'vue-class-component';

const namespace = 'sidebar';

export default class SidebarFooter extends Vue {
    @Getter('isShow', { namespace }) isShow?: boolean;

    @Action('switchView', { namespace }) switchView?: void;
}
