
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';

@Options({
  props: {
    fields: Array as PropType<string[]>,
  },
})
export default class SidebarInspect extends Vue {
    fields!: Array<string>
}
