import { GetterTree } from 'vuex';
import { SidebarState } from './types';
import { RootState } from '../../types';

const getters: GetterTree<SidebarState, RootState> = {
  isShow(state) { return state.isShow; },
  isShowUploaderModal(state) { return state.isShowUploaderModal; },
};

export default getters;
