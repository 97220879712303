import { GetterTree } from 'vuex';
import { FileInfoState } from './types';
import { RootState } from '../../types';

const getters: GetterTree<FileInfoState, RootState> = {
  filesIformation(state) { return state.files; },
  connectionStatus(state) { return state.connectionStatus; },
  metrics(state) { return state.metrics; },
  fields(state) { return state.fields; },
  uploadFile(state) { return state.uploadFile; },
};

export default getters;
