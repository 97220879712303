// profile/mutations.ts
import { MutationTree } from 'vuex';
import { SidebarState } from './types';

const mutations: MutationTree<SidebarState> = {
  showChange(state) {
    state.isShow = !state.isShow;
  },
  setCloseUploaderModal(state) {
    state.isShowUploaderModal = false;
  },
  setOpenUploaderModal(state) {
    state.isShowUploaderModal = true;
  },
};

export default mutations;
